import { JsonMapFile, Layer } from "./JsonMapFile";
import { MovementAnimation } from "./MovementAnimation";
import { PlayerSprite } from "./PlayerSprite";
import { Direction } from "./Direction";
import { Position } from "./Position";
import { MapSprite } from "./MapSprite";
import { Matrix } from "./Matrix";

interface SceneConstructor {
  player: PlayerSprite;
  background: MapSprite;
  foreground: MapSprite;
  context: CanvasRenderingContext2D;
  mapData: JsonMapFile;
  position: Position;
}

export class Scene {
  player: PlayerSprite;
  background: MapSprite;
  foreground: MapSprite;
  collisionsMap: Matrix<number>;
  context: CanvasRenderingContext2D;
  mapData: JsonMapFile;
  tileWidth: number;
  tileHeight: number;
  width: number;
  height: number;
  position: Position;
  movementAnimation: MovementAnimation;
  constructor({
    player,
    background,
    foreground,
    position,
    context,
    mapData,
  }: SceneConstructor) {
    this.player = player;
    this.background = background;
    this.foreground = foreground;
    this.context = context;
    this.mapData = mapData;
    this.position = position;
    this.tileWidth = this.mapData.tilewidth * 4;
    this.tileHeight = this.mapData.tileheight * 4;
    this.width = this.mapData.width;
    this.height = this.mapData.height;
    this.collisionsMap = transformTileData(
      this.mapData.layers.find((l) => l.name === "Collisions")
    );
    this.movementAnimation = new MovementAnimation({
      tileFrame: 0,
      framesPerTile: 20,
      ticksPerFrame: 8,
    });
    this.player.setMovementAnimation(this.movementAnimation);
    this.background.setMovementAnimation(this.movementAnimation);
    this.foreground.setMovementAnimation(this.movementAnimation);
  }
  draw() {
    this.background.draw(this.context, this.position);
    this.player.draw();
    this.foreground.draw(this.context, this.position);
  }
  changePositionX(x: number) {
    this.position.x = this.position.x + x;
  }
  changePositionY(y: number) {
    this.position.y = this.position.y + y;
  }
  canMove(direction: Direction) {
    switch (direction) {
      case Direction.Left:
        return !this.collisionsMap.get(this.position.x - 1, this.position.y);
      case Direction.Right:
        return !this.collisionsMap.get(this.position.x + 1, this.position.y);
      case Direction.Up:
        return !this.collisionsMap.get(this.position.x, this.position.y - 1);
      case Direction.Down:
        return !this.collisionsMap.get(this.position.x, this.position.y + 1);
    }
  }
  move(direction: Direction) {
    switch (direction) {
      case Direction.Left:
        return this.changePositionX(-1);
      case Direction.Right:
        return this.changePositionX(1);
      case Direction.Up:
        return this.changePositionY(-1);
      case Direction.Down:
        return this.changePositionY(1);
    }
  }
  start() {
    window.requestAnimationFrame(this.start.bind(this));
    this.draw();
    if (this.movementAnimation.isAnimated) {
      if (this.movementAnimation.curTicks === 0) {
        this.movementAnimation.nextFrame();
      }
      this.movementAnimation.nextTick();
      const canMove = this.canMove(this.movementAnimation.getDirection());
      this.movementAnimation.isMoving = canMove;
      if (this.movementAnimation.getTick() === 0 && canMove) {
        this.move(this.movementAnimation.getDirection());
      }
    }
  }
}

function transformTileData(layer: Layer): Matrix<number> {
  const width = layer.width;
  const data = layer.data.reduce<number[][]>((prev, val, i) => {
    if (i % width === 0) {
      prev.push([val]);
    } else {
      prev[prev.length - 1].push(val);
    }
    return prev;
  }, []);
  return new Matrix<number>(data);
}
