import { AsyncImage } from "./AsyncImage";
import { Direction } from "./Direction";
import { Frames } from "./Frames";
import { MovementAnimation } from "./MovementAnimation";

interface PlayerSpriteConstructor {
  context: CanvasRenderingContext2D;
  movementAnimation?: MovementAnimation;
}

export class PlayerSprite {
  movementAnimation: MovementAnimation;
  context: CanvasRenderingContext2D;
  imageDown: HTMLImageElement;
  imageLeft: HTMLImageElement;
  imageUp: HTMLImageElement;
  imageRight: HTMLImageElement;
  constructor({ context, movementAnimation }: PlayerSpriteConstructor) {
    this.context = context;
    this.movementAnimation = movementAnimation;
  }
  setMovementAnimation(movementAnimation: MovementAnimation) {
    this.movementAnimation = movementAnimation;
  }
  async load() {
    const [down, up, left, right] = await Promise.all([
      AsyncImage.load("public/playerDown.png"),
      AsyncImage.load("public/playerUp.png"),
      AsyncImage.load("public/playerLeft.png"),
      AsyncImage.load("public/playerRight.png"),
    ]);
    this.imageDown = down;
    this.imageUp = up;
    this.imageLeft = left;
    this.imageRight = right;
  }

  private getImage(direction: Direction) {
    switch (direction) {
      case Direction.Up:
        return this.imageUp;
      case Direction.Left:
        return this.imageLeft;
      case Direction.Right:
        return this.imageRight;
      default:
        return this.imageDown;
    }
  }
  draw() {
    const image = this.getImage(this.movementAnimation.direction);
    const cWidth = this.context.canvas.clientWidth;
    const cHeight = this.context.canvas.clientHeight;
    const spriteWidth = image.width / this.movementAnimation.amountFrames;
    const spriteHeight = image.height;
    this.context.drawImage(
      image,
      this.movementAnimation.getFrame() * spriteWidth,
      0,
      spriteWidth,
      spriteHeight,
      cWidth / 2 - spriteWidth / 2,
      cHeight / 2 - spriteHeight / 2 - 10, // fürs alignment
      spriteWidth,
      spriteHeight
    );
  }
}
