import { Direction } from "./Direction";
import { MovementAnimation } from "./MovementAnimation";
import { Position } from "./Position";

export class MapSprite {
  image: HTMLImageElement;
  movementAnimation: MovementAnimation;
  constructor() {
    this.image = new Image();
  }
  setMovementAnimation(movementAnimation: MovementAnimation) {
    this.movementAnimation = movementAnimation;
  }
  async load(src: string) {
    return new Promise<void>((resolve, reject) => {
      this.image.src = src;
      this.image.addEventListener("load", () => resolve());
    });
  }
  draw(ctx: CanvasRenderingContext2D, position: Position) {
    const [x, y] = getOffsetFromPosition(position, this.movementAnimation);
    ctx.drawImage(this.image, x, y);
  }
}

export function getOffsetFromPosition(
  pos: Position,
  ma: MovementAnimation
): [number, number] {
  const TILE_WIDTH = 48;
  const TILE_HEIGHT = 48;
  let offsetX = 0;
  let offsetY = 0;
  if (ma.isAnimated && ma.isMoving) {
    const offset = (TILE_WIDTH * ma.tileFrame) / ma.framesPerTile;
    if (ma.getDirection() === Direction.Left) {
      offsetX = offset;
    } else if (ma.getDirection() === Direction.Right) {
      offsetX = -offset;
    } else if (ma.getDirection() === Direction.Up) {
      offsetY = offset;
    } else if (ma.getDirection() === Direction.Down) {
      offsetY = -offset;
    }
  }
  return [
    TILE_WIDTH * (6 - pos.x) + offsetX,
    TILE_HEIGHT * (6 - pos.y) + offsetY,
  ];
}
