import { PlayerSprite } from "./PlayerSprite";
import { Scene } from "./Scene";
import { MapSprite } from "./MapSprite";
import mapData from "./data/island.json";

const canvas = document.querySelector("canvas");
canvas.width = 624;
canvas.height = 624;
const c = canvas.getContext("2d");

const playerSprite = new PlayerSprite({ context: c });
const background = new MapSprite();
const foreground = new MapSprite();
const scene = new Scene({
  player: playerSprite,
  background,
  foreground,
  position: { x: 6, y: 10 },
  context: c,
  mapData,
});

async function start() {
  await Promise.all([
    background.load("public/island.png"),
    foreground.load("public/island_foreground.png"),
    playerSprite.load(),
  ]);
  scene.start();
}

start();
