import { Direction } from "./Direction";
import { DirectionHistory } from "./DirectionHistory";

interface MovementAnimationConstructor {
  tileFrame?: number;
  framesPerTile?: number;
  isAnimated?: boolean;
  direction?: Direction;
  ticksPerFrame?: number;
}

export class MovementAnimation {
  tileFrame: number;
  framesPerTile: number;
  isAnimated: boolean;
  isMoving: boolean;
  direction: Direction;
  nextDirection: Direction;
  curTicks?: number;
  ticksPerFrame?: number;
  isAboutToStop: boolean;
  amountFrames: number;
  currentFrame: number;
  history: DirectionHistory;
  constructor(
    {
      tileFrame,
      framesPerTile,
      isAnimated,
      direction,
      ticksPerFrame,
    }: MovementAnimationConstructor = {
      tileFrame: 0,
      framesPerTile: 16,
      isAnimated: false,
      direction: Direction.Down,
    }
  ) {
    this.tileFrame = tileFrame;
    this.framesPerTile = framesPerTile;
    this.isAnimated = isAnimated;
    this.direction = direction;
    this.curTicks = 0;
    this.ticksPerFrame = ticksPerFrame;
    this.currentFrame = 0;
    this.amountFrames = 4;
    this.history = new DirectionHistory();
    window.addEventListener("keydown", (e) => {
      switch (e.key) {
        case "a":
          this.setDirection(Direction.Left);
          break;
        case "d":
          this.setDirection(Direction.Right);
          break;
        case "w":
          this.setDirection(Direction.Up);
          break;
        case "s":
          this.setDirection(Direction.Down);
          break;
      }
    });
    window.addEventListener("keyup", (e) => {
      switch (e.key) {
        case "a":
          this.stopMoving(Direction.Left);
          break;
        case "d":
          this.stopMoving(Direction.Right);
          break;
        case "w":
          this.stopMoving(Direction.Up);
          break;
        case "s":
          this.stopMoving(Direction.Down);
          break;
      }
    });
  }
  stopMoving(direction: Direction) {
    this.isAboutToStop = true;
    this.currentFrame = 0;
    this.history.remove(direction);
  }
  getDirection(): Direction {
    return this.direction;
  }
  setDirection(direction: Direction) {
    this.history.add(direction);
    this.isAnimated = true;
    if (this.currentFrame > 0 && direction !== this.direction) {
      this.nextDirection = direction;
    } else {
      this.direction = direction;
    }
  }
  nextTick() {
    this.tileFrame = (this.tileFrame + 1) % this.framesPerTile;
    this.curTicks = (this.curTicks + 1) % this.ticksPerFrame;
    if (this.isAboutToStop && this.tileFrame === 0) {
      this.isAnimated = false;
      this.isAboutToStop = false;
      this.currentFrame = 0;
    }
    if (this.nextDirection && this.tileFrame === 1) {
      this.direction = this.nextDirection;
      this.nextDirection = null;
    }
  }
  getTick(): number {
    return this.tileFrame;
  }
  getFrame(): number {
    return this.currentFrame;
  }
  nextFrame() {
    this.currentFrame = (this.currentFrame + 1) % this.amountFrames;
  }
  setDefaultFrame() {
    this.currentFrame = 0;
  }
}
