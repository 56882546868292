import { Direction } from "./Direction";

export class DirectionHistory {
  private array: Direction[];
  constructor() {
    this.array = [];
  }
  add(dir: Direction) {
    if (this.current() === dir) return;
    this.remove(dir);
    this.array.push(dir);
  }
  remove(dir: Direction) {
    this.array = this.array.filter((val) => val !== dir);
  }
  current(): Direction {
    return this.array[this.array.length - 1] || null;
  }
}
